import useDetectCountry from '@hooks/useDetectCountry'
import { BROWSER_STORAGE_KEYS } from '@lib/data/browser-storage-keys'
import { getFromLocalStorage } from '@lib/utils/local-storage'
import { NextPage } from 'next'
import React from 'react'
import { AlertTriangle } from 'react-feather'
import PhoneInput from 'react-phone-input-2'
import DottedCardBackgroundWrapper from '../common/dotted-card-bg'
import LoginButton from './password-login-btn'
import TestimonialCard from './testimonial-card'

type Props = {
  phone: string
  loading: boolean
  errors: any
  handlePhoneInputChange: (value: string) => void
  handlePhoneInputFormSubmit: () => void
  redirectToPasswordPage: () => void
}

const TestimonialCardWithDottedBackground = DottedCardBackgroundWrapper(
  TestimonialCard
)

const EnterPhoneInput: NextPage<Props> = ({
  loading,
  phone,
  handlePhoneInputChange,
  handlePhoneInputFormSubmit,
  errors,
  redirectToPasswordPage,
}) => {
  const { countryCode } = useDetectCountry({
    isLowerCase: true,
    isDeterminedViaSystemTimezoneOnly: true,
  })

  // This is required to to decide when CTA should be the primary one
  let hasPassword: boolean
  const hasPasswordKey = getFromLocalStorage(BROWSER_STORAGE_KEYS.HAS_PASSWORD)

  if (hasPasswordKey) {
    hasPassword = hasPasswordKey === 'true' ? true : false
  }

  const handleEnterOnInput = () => {
    if (hasPassword) {
      redirectToPasswordPage()
    } else {
      handlePhoneInputFormSubmit()
    }
  }

  return (
    <div className="flex items-start justify-between flex-1 h-full bg-white lg:items-center lg:bg-transparent">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="hidden w-full pr-5 lg:block sm:w-1/2">
            <TestimonialCardWithDottedBackground />
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <div className="px-5">
              <div className="leading-tight text-grey text-5xl font-700">
                <p>Let&apos;s get started</p>
              </div>
              <div className="max-w-md my-5 mt-10">
                <div>
                  <label className="mb-1 text-grey font-500">
                    Enter your phone number
                  </label>
                  <PhoneInput
                    containerClass="font-sans text-base leading-tight"
                    inputClass="appearance-none border border-grey-500 rounded w-full py-2 pl-16 pr-4 text-grey leading-tight focus:outline-none"
                    country={countryCode}
                    value={phone}
                    searchStyle={{
                      marginBottom: '2px',
                    }}
                    enableSearch
                    countryCodeEditable={false}
                    onChange={handlePhoneInputChange}
                    onEnterKeyPress={handleEnterOnInput}
                    masks={{ in: '..... .....' }}
                    inputProps={{
                      id: 'phone',
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                      placeholder: 'Phone number',
                    }}
                  />
                </div>
                {errors.phone && (
                  <div className="flex items-center space-x-2 bg-red-50 border-l-4 border-orange py-1 mt-2">
                    <AlertTriangle size={15} className="text-red-200 ml-2" />
                    <p className="text-sm inline-block">{errors.phone}</p>
                  </div>
                )}
                {errors.nonFieldErrors && (
                  <div className="flex items-center space-x-2 bg-red-50 border-l-4 border-orange py-1 mt-2">
                    <AlertTriangle size={15} className="text-red-200 ml-2" />
                    <p className="text-sm inline-block">
                      {errors.nonFieldErrors}
                    </p>
                  </div>
                )}
                {errors.detail && (
                  <div className="flex items-center space-x-2 bg-red-50 border-l-4 border-orange py-1 mt-2">
                    <AlertTriangle size={15} className="text-red-200 ml-2" />
                    <p className="text-sm inline-block">{errors.detail}</p>
                  </div>
                )}
                <div className="flex flex-wrap gap-5 mt-10">
                  {hasPassword && (
                    <>
                      <LoginButton
                        isDisabled={loading || !phone}
                        isLoading={loading}
                        label="Login with password"
                        handleClick={redirectToPasswordPage}
                        isPrimary
                      />
                      <LoginButton
                        isDisabled={loading || !phone}
                        isLoading={loading}
                        label="Login with OTP"
                        handleClick={handlePhoneInputFormSubmit}
                      />
                    </>
                  )}
                  {!hasPassword && (
                    <>
                      <LoginButton
                        isDisabled={loading || !phone}
                        isLoading={loading}
                        label="Login with OTP"
                        handleClick={handlePhoneInputFormSubmit}
                        isPrimary
                      />
                      <LoginButton
                        isDisabled={loading || !phone}
                        isLoading={loading}
                        label="Login with password"
                        handleClick={redirectToPasswordPage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full h-auto my-16 lg:hidden lg:w-1/2 md:px-5">
              <TestimonialCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnterPhoneInput
