import { LOGIN_REF, REGISTER_REF } from '@common/constants'
import EnterPhoneInput from '@components/auth/phone-input'
import { requestLoginOtp } from '@containers/auth/utils'
import { validatePhone } from '@containers/common/validators'
import useSegment, { SegmentEventEnum } from '@hooks/useSegment'
import { isEmptyObject } from '@lib/utils/common'
import Router from 'next/router'
import React, { useState } from 'react'

type Props = {
  next: string
}

type Query = {
  next?: string
  phone: string
  source?: string
}

const LoginFlow = ({ next }: Props) => {
  const track = useSegment()
  const [loginData, setLoginData] = useState({
    // User input
    phone: '',
    otp: '',

    // Server state and response
    response: {},
    loading: false,
    errors: {},
  })

  const changeLoginData = (data: any) => {
    setLoginData((prev) => ({ ...prev, ...data }))
  }

  // OTP based login
  const handlePhoneInputChange = (value: string) => {
    changeLoginData({
      phone: value,
    })
  }

  const handlePhoneInputFormSubmit = async () => {
    if (loginData.loading) return

    changeLoginData({
      loading: true,
    })

    const validationErrors = validatePhone(loginData.phone)
    if (!isEmptyObject(validationErrors)) {
      changeLoginData({ loading: false, errors: validationErrors })
      return
    }

    const formBody = {
      phone: `+${loginData.phone}`,
    }
    const result = await requestLoginOtp(formBody)

    const currentQuery = Router.query
    const query: Query = { ...currentQuery, phone: loginData.phone }
    if (next) query.next = next

    if (result.status === 200) {
      track(SegmentEventEnum.LOGIN_CLICK_LOGIN_EXISTING_USER)

      query.source = LOGIN_REF

      Router.push({
        pathname: '/login/verify-otp/',
        query,
      })
    } else if (result.status === 204) {
      track(SegmentEventEnum.LOGIN_CLICK_LOGIN_NEW_USER)
      query.source = REGISTER_REF

      Router.push({
        pathname: '/register/',
        query,
      })
    } else {
      const errors = await result.json()
      changeLoginData({ errors })
    }

    changeLoginData({ loading: false })
  }
  const redirectToPasswordPage = () => {
    const currentQuery = Router.query
    const query: Query = { ...currentQuery, phone: loginData.phone }
    if (next) query.next = next
    Router.push({
      pathname: '/login/password/',
      query,
    })
  }

  return (
    <EnterPhoneInput
      phone={loginData.phone}
      loading={loginData.loading}
      errors={loginData.errors}
      handlePhoneInputChange={handlePhoneInputChange}
      handlePhoneInputFormSubmit={handlePhoneInputFormSubmit}
      redirectToPasswordPage={redirectToPasswordPage}
    />
  )
}

export default LoginFlow
