import { useRouter } from 'next/router'

import { loginSeo } from '@lib/seo'

import LoginFlow from '@containers/auth/login'
import RegistrationNavBar from '@components/auth/navbar'
import I18nNextSeo from '@components/common/i18n/i18n-next-seo'

export const LoginPage = (): JSX.Element => {
  const router = useRouter()
  const next = router.query.next?.toString()
  return (
    <>
      <I18nNextSeo {...loginSeo} />
      <div className="flex flex-col slant-orange-split min-h-screen h-full">
        <RegistrationNavBar />
        <LoginFlow next={next} />
      </div>
    </>
  )
}

export default LoginPage
