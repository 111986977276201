import React, { FC } from 'react'
import Button from '@components/atoms/button'
import classNames from 'classnames'
import Spinner from '@components/common/spinner'

type Props = {
  isDisabled: boolean
  isLoading: boolean
  isPrimary?: boolean
  handleClick?: () => void
  label: string
}

const LoginButton: FC<Props> = ({
  isDisabled,
  isLoading,
  isPrimary = false,
  handleClick,
  label,
}) => {
  return (
    <div className="flex">
      {isPrimary && (
        <Button disabled={isDisabled} loading={isLoading} onClick={handleClick}>
          {label}
        </Button>
      )}

      {!isPrimary && (
        <button
          disabled={isDisabled}
          onClick={handleClick}
          className={classNames('font-600', {
            'text-orange': !isDisabled,
            'text-orange-400 cursor-not-allowed': isDisabled,
          })}
        >
          {isLoading && <Spinner classes="h-5 w-5 text-white" />}
          {!isLoading && label}
        </button>
      )}
    </div>
  )
}

export default LoginButton
